@import url('https://fonts.googleapis.com/css?family=Roboto:500&display=swap');
// Write your custom style. Feel free to split your code to several files
body {
  // color: blue;
}
.logo-codigoweb{
  font-family: 'Roboto', sans-serif;
}
.product-caption{
    font-size: 1.65rem;
}
.software-logo{
  color: #ffffff;
}
.navbar-stick-dark.stick .navbar-brand>.software-logo{
  color: rgba(117,117,117,0.8);
}
